.plateviewer {
  padding: 70px;
  margin-left: auto;
  margin-right: auto;
}

.configuration {
  height: 500px;
  padding: 70px;
  margin-left: auto;
  margin-right: auto;
}

select {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
}

textarea {
  font-family: 'Fira Code', 'Menlo', 'Courier New', Courier, monospace;
}
